import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from 'components/Layout'
import styled from 'styled-components'
import SEO from '../components/SEO'

const Component = ({ data }) => {
  const { edges } = data.images
  return (
    <Layout>
      <SEO data={{ title: 'Impressionen' }} />
      <h1>Impressionen</h1>
      <Images>
        {edges.map(image => {
          return (
            <Item>
              <Img fluid={image.node.fluid} />
            </Item>
          )
        })}
      </Images>
    </Layout>
  )
}

export const query = graphql`
  query impressions {
    images: allContentfulAsset(filter: { title: { regex: "/^impression/" } }) {
      edges {
        node {
          id
          title
          fluid {
            ...GatsbyContentfulFluid_tracedSVG
          }
        }
      }
    }
  }
`

export default Component

const Images = styled.div`
  display: flex;
  flex-direction: column;
  //justify-content: center;
  align-items: center;
  width: 100%;
`
const Item = styled.div`
  flex-grow: 1;
  margin: 2rem;
  width: 500px;
`
